import axiosIns from '@/libs/axios.js';

class LessonService {
    getStudentLesson(){
        return axiosIns.get('student-lesson').then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }
    lessonByCourse(pageNumber=1, courseId){
        return axiosIns.get(`lesson-course/${courseId}?page=${pageNumber}`).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }
}

export default new LessonService();